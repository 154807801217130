
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images
import appleLogo from "assets/images/logos/gray-logos/logo-apple.svg";
import facebookLogo from "assets/images/logos/gray-logos/logo-facebook.svg";
import nasaLogo from "assets/images/logos/gray-logos/logo-nasa.svg";
import vodafoneLogo from "assets/images/logos/gray-logos/logo-vodafone.svg";
import digitalOceanLogo from "assets/images/logos/gray-logos/logo-digitalocean.svg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2">Customer Reviews</MKTypography>
          <MKTypography variant="h2" color="info" textGradient mb={2}>
            See What Our Customer Says About Us...
          </MKTypography>
          {/* <MKTypography variant="body1" color="text" mb={2}>
            Many Fortune 500 companies, startups, universities and governmental institutions love
            Creative Tim&apos;s products.
          </MKTypography> */}
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Ayush Sharma"
              date="1 day ago"
              review="We go to udaipur in rajasthan that time my group book a A. C innova from jaipur to udaipur tour taxi was very wonderful and driver 
              was also nice he give us very nice service driving was very careful,Price also very reasonable. "
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="info"
              name="Manish Muhal"
              date="2 days ago"
              review="Booked a cab for a family trip from Jaipur to Jodhpur. The car was well-maintained and clean. The driver was punctual, polite, and drove safely throughout the journey. Highly satisfied with the service and would definitely recommend it to others!"
              rating={4.5}
            />

          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Vikash Pandey"
              date="1 week ago"
              review="I find one-way taxi from Delhi airport to Jaipur taxi 
              was on time and condition also amazing neet and clean driver was in well dressed driving was very safe."
              rating={5}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
