import dzire from "../../../../assets/images/ee37abefc382ad35e1f35453bf147844.jpg";

import toyota from "../../../../assets/images/18380b0ad087835d0dc987c870929f49.jpg";

import maruti from "../../../../assets/images/1aa29dc9e7f04938074471d04d88cc5a.jpg";
import Tempo from "../../../../assets/images/tempo.jpg";
import Innova from "../../../../assets/images/8d93c76a0374383e5b29dbcad93ad88e.jpg";
import Crysta from "../../../../assets/images/innova2.jpg";



export default [
  {
    title: "Rent a Car & Hit the Road in Style",
    description: "Choose from our premium fleet and enjoy a smooth, comfortable ride with features that enhance your travel experience.",
    items: [
      {
        image: dzire,
        name: "Swift Dzire AC 4+1",
        features: [
          { icon: "ac_unit", text: "AC" },
          { icon: "gps_fixed", text: "GPS Fitted" },
          { icon: "map", text: "250 KM/Day" },
          { icon: "directions_car", text: "Well Maintained Cars" },
        ],
        route: "/sections/navigation/navbars",
      },
      {
        image: toyota,
        name: "Toyota Etios Car AC 4+1",
        route: "/sections/navigation/nav-tabs",
        features: [
          { icon: "ac_unit", text: "AC" },
          { icon: "gps_fixed", text: "GPS Fitted" },
          { icon: "map", text: "250 KM/Day" },
          { icon: "directions_car", text: "Well Maintained Cars" },
        ],
      },
      {
        image: maruti,
        name: "Maruti Ertiga AC 6+1",
        features: [
          { icon: "ac_unit", text: "AC" },
          { icon: "gps_fixed", text: "GPS Fitted" },
          { icon: "map", text: "250 KM/Day" },
          { icon: "directions_car", text: "Well Maintained Cars" },
        ],
        route: "/sections/navigation/pagination",
      },
      {
        image: Tempo,
        name: "Tempo Traveller AC 17+1",
        features: [
          { icon: "ac_unit", text: "AC" },
          { icon: "gps_fixed", text: "GPS Fitted" },
          { icon: "map", text: "250 KM/Day" },
          { icon: "directions_car", text: "Well Maintained Cars" },
        ],
        route: "/sections/navigation/pagination",
      },
      {
        image: Innova,
        name: "Toyota Innova AC 7+1",
        features: [
          { icon: "ac_unit", text: "AC" },
          { icon: "gps_fixed", text: "GPS Fitted" },
          { icon: "map", text: "250 KM/Day" },
          { icon: "directions_car", text: "Well Maintained Cars" },
        ],
        route: "/sections/navigation/pagination",
      },
      {
        image: Crysta,
        name: "Innova Crysta AC 6+1",
        features: [
          { icon: "ac_unit", text: "AC" },
          { icon: "gps_fixed", text: "GPS Fitted" },
          { icon: "map", text: "250 KM/Day" },
          { icon: "directions_car", text: "Well Maintained Cars" },
        ],
        route: "/sections/navigation/pagination",
      },
    ],
  },
];
