// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/NST tour and travels.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Online Jaipur Taxi",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
    },
    {
      icon: <TwitterIcon />,
    },
    {
      icon: <GitHubIcon />,
    },
  ],
  menus: [
    {
      name: "Email Address",
      items: [
        { name: "info@onlinejaipurtaxi.in", href: "#" },
        
      ],
    },
    {
      name: "Contact Number",
      items: [
        { name: "+91-8740888883",href: "#"},
      ],
    },
    {
      name: "Office Hours",
      items: [
        { name: "All Days :: 24X7 Available", href: "#"},
      ],
    },
   
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} PM IT Solution{" "}
      <MKTypography
        component="a"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
      </MKTypography>
      .
    </MKTypography>
  ),
};
