import Icon from "@mui/material/Icon";

// Pages
import AboutUs from "layouts/pages/landing-pages/about-us";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import SignIn from "layouts/pages/authentication/sign-in";
import Presentation from "layouts/pages/presentation";
import PopularFleets from "pages/Presentation/sections/PopularFleets";

const routes = [
  {
    name: "",
    route: "/book_now",
    component: <SignIn />,
  },
  {
    name: "Home",
    icon: <Icon>home</Icon>,
    route: "/home",
    component: <Presentation />,
  },
  {
    name: "About Us",
    icon: <Icon>info</Icon>, 
    route: "/about-us",
    component: <AboutUs />,
  },
  {
    name: "Popular Fleets",
    route: "/popular/fleets",

    icon: <Icon>directions_car</Icon>, 
    component: <PopularFleets />,

  },
  {
    name: "Contact Us",
    icon: <Icon>contacts</Icon>, 
    route: "/contact-us",
    component: <ContactUs />,
  },
];

export default routes;
