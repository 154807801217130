import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import img from "assets/images/bt45.jpg";
import MKButton from "components/MKButton";

function BuiltByDevelopers() {
  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={6}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${img})`, // Use the imported image
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6 } }}>
          <MKTypography variant="h4" color="white" fontWeight="bold">
            📳 Call Us to Book a Taxi 🚕
          </MKTypography>
          <MKTypography variant="h1" color="white" mb={1}>
            Complex Documentation
          </MKTypography>
          <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
            We provide 24×7 Customer Support. The cars are always clean and sanitized. We hire trustworthy drivers with a clean record and ensure they are fully verified by authorities. All our drivers are vaccinated for your safety.
          </MKTypography>
          <MKButton
            variant="gradient"
            color="info"
            size="large"
            fontWeight="regular"
            sx={{
              display: "flex",
              alignItems: "center",
              "& .material-icons-round": {
                fontSize: "1.125rem",
                transform: `translateX(3px)`,
                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)"
              },
              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: `translateX(6px)`,
              },
            }}
          >
            Book Your Ride Now <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKButton>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BuiltByDevelopers;
