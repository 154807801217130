
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";

import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/tw2.jpg";
import bgBack from "assets/images/b4523.jpg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Online Jaipur Taxi
                  
                  </>
                }
                description="TRAVELING TO ANOTHER CITY WAS NEVER THIS EASY."
              />
              <RotatingCardBack
                image={bgBack}
                title="TRAVELING TO ANOTHER CITY WAS NEVER THIS EASY."
                action={{
                  type: "internal",
                  route: "/sections/page-sections/page-headers",
                  label: "Know More",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} >
                <DefaultInfoCard
                  title="TRAVELING TO ANOTHER CITY WAS NEVER THIS EASY."
                  description="Once booked, a well-serviced drive along with a verified driver reaches your doorsteps.
                    ONLINE JAIPUR TAXI brings you a hassle-free, convenient, safe and exceptional intercity cab rental service, the quality of which cannot be compared with any other drive of the fleet.
                    This cab rental venture was established with a vision of easing the hardships of commuters who find it extremely hard to book their ride for city-to-city travel. Further fueling our drive; our promise of constant reliability, 
                    unshakable ethics and exceptional service gives us an advantage over the competitors."
                />
              </Grid>

            </Grid>

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
