import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import data from "pages/Presentation/sections/data/pagesData";

function Pages() {
  const renderData = data.map(({ image, name, route, description }) => (
    <Grid item xs={12} md={4} key={name}>
      <Link to={route} style={{ textDecoration: "none" }}>
        <MKBox
          sx={{
            position: "relative",
            overflow: "hidden",
            borderRadius: 2,
            "&:hover .overlay": { opacity: 0.8 },
          }}
        >
          {/* Image */}
          <MKBox
            component="img"
            src={image}
            alt={name}
            sx={{
              width: "100%",
              height: "auto",
              opacity: 0.6,
              display: "block",
              transition: "opacity 0.3s ease-in-out",
            }}
          />

          {/* Text Overlay */}
          <MKBox
            className="overlay"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              opacity: 1,
              transition: "opacity 0.3s ease-in-out",
              px: 2,
            }}
          >
            <MKTypography variant="h5" color="white" fontWeight="bold" sx={{ mb: 1 }}>
              {name}
            </MKTypography>
            <MKTypography variant="body2" color="white" sx={{ fontWeight: "light" }}>
              {description}
            </MKTypography>
          </MKBox>
        </MKBox>
      </Link>
    </Grid>
  ));

  return (
    <MKBox component="section" py={6}>
      <Container sx={{ mt: { xs: 8, lg: 16 } }}>
        <Grid container spacing={3}>
          {renderData}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Pages;
