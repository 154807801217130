import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function ExampleCard({ image, name, count, pro, features, ...rest }) {
  const imageTemplate = (
    <MKBox
      bgColor="white"
      borderRadius="xl"
      shadow="lg"
      minHeight="10rem"
      sx={{
        overflow: "hidden",
        transform: "perspective(999px) rotateX(0deg) translate3d(0, 0, 0)",
        transformOrigin: "50% 0",
        backfaceVisibility: "hidden",
        willChange: "transform, box-shadow",
        transition: "transform 200ms ease-out",
        "&:hover": {
          transform: "perspective(999px) rotateX(7deg) translate3d(0px, -4px, 5px)",
        },
      }}
      {...rest}
    >
      {pro && (
        <MKBox position="absolute" top={0} right={0} zIndex={2} p={1}>
          {/* SVG Icon for Pro */}
        </MKBox>
      )}
      <MKBox
        component="img"
        src={image}
        alt={name}
        width="100%"
        my="auto"
        opacity={pro ? 0.6 : 1}
      />
    </MKBox>
  );

  return (
    <MKBox position="relative">
      {pro ? (
        <Tooltip title="Pro Element" placement="top">
          {imageTemplate}
        </Tooltip>
      ) : (
        imageTemplate
      )}
      <MKBox mt={1} ml={1} lineHeight={1}>
        {name && (
          <MKTypography variant="h6" fontWeight="bold">
            {name}
          </MKTypography>
        )}
        {count > 0 && (
          <MKTypography variant="button" fontWeight="regular" color="secondary">
            {count} {count === 1 ? "Example" : "Examples"}
          </MKTypography>
        )}
        {/* Features Rendering */}
        {features && features.length > 0 && (
          <MKBox mt={2}>
            {features.map(({ icon, text }, index) => (
              <MKBox display="flex" alignItems="center" key={index} mt={0.5}>
                <Icon sx={{ mr: 1 }}>{icon}</Icon>
                <MKTypography variant="body2">{text}</MKTypography>
              </MKBox>
            ))}
          </MKBox>
        )}
      </MKBox>
    </MKBox>
  );
}

ExampleCard.defaultProps = {
  name: "",
  count: 0,
  pro: false,
  features: [],
};

ExampleCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string,
  count: PropTypes.number,
  pro: PropTypes.bool,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
};

export default ExampleCard;
