import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Image
import carImage from "assets/images/b4523.jpg"; 

function Info() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container spacing={4} alignItems="center">
          {/* Left Side - Image */}
          <Grid item xs={12} md={6}>
            <MKBox
              component="img"
              src={carImage}
              alt="Car Image"
              width="100%"
              borderRadius="lg"
              sx={{
                boxShadow: 3,
              }}
            />
          </Grid>

          {/* Right Side - Text */}
          <Grid item xs={12} md={6}>
            <MKTypography variant="h3" fontWeight="bold" gutterBottom>
              WHY CHOOSE US
            </MKTypography>

            <MKTypography variant="body1" color="textSecondary" mb={2}>
              We want to provide the best car rental, cab booking, and travel services all over India at the
              lowest prices. Our aim is to become the largest car rental & cab booking company in Jaipur.
            </MKTypography>

            <MKTypography variant="body1" color="primary" fontWeight="bold" mb={1}>
              You must have a solid reason to choose us. Being a leading cab & taxi service in Jaipur:
            </MKTypography>

            <ul style={{ paddingLeft: "1.5rem" }}>
              <li>
                <MKTypography variant="h6" color="primary" fontWeight="bold">
                  Affordable Cost Guarantee:
                </MKTypography>
                <MKTypography variant="body2" mb={2}>
                  We are proudly considered one of the most cost-effective cab services in Jaipur!
                </MKTypography>
              </li>

              <li>
                <MKTypography variant="h6" color="primary" fontWeight="bold">
                  High-Quality Service:
                </MKTypography>
                <MKTypography variant="body2" mb={2}>
                  We ensure top-notch service to provide the best travel experience for our valuable customers.
                </MKTypography>
              </li>

              <li>
                <MKTypography variant="h6" color="primary" fontWeight="bold">
                  Reliable Rental Service:
                </MKTypography>
                <MKTypography variant="body2" mb={2}>
                  Whether you need an AC/Non-AC taxi, tempo, or even a bus, our drivers are experienced and
                  trained.
                </MKTypography>
              </li>

              <li>
                <MKTypography variant="h6" color="primary" fontWeight="bold">
                  Customize Tour Packages:
                </MKTypography>
                <MKTypography variant="body2">
                  If you have special demands, we can customize your tour packages to meet your needs.
                </MKTypography>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Info;
