
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import Info from "pages/Presentation/sections/Info";

import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import image from "assets/images/tw2.jpg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12}>
                <MKBox >
                  <DefaultInfoCard
                    title="TRAVELING TO ANOTHER CITY WAS NEVER THIS EASY."
                    description="Once booked, a well-serviced drive along with a verified driver reaches your doorsteps.

                          ONLINE JAIPUR TAXI brings you a hassle-free, convenient, safe and exceptional intercity cab rental service, the quality of which cannot be compared with any other drive of the fleet.
                          This cab rental venture was established with a vision of easing the hardships of commuters who find it extremely hard to book their ride for city-to-city travel. Further fueling our drive; our promise of constant reliability, unshakable ethics and exceptional service gives us an advantage over the competitors."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={image}
              title="Book Your Ride with Ease"
              description="Experience a hassle-free and luxurious intercity travel service with ONLINE JAIPUR TAXI. Our reliable drivers and well-maintained cabs ensure a comfortable journey every time. Click below to book now and travel stress-free!"
              action={{
                type: "internal",
                route: "/book_now",
                color: "info",
                label: "Book Now",
              }}
            />

          </Grid>
          <Info />

        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
