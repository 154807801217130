import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

// Material Kit 2 React routes
import routes from "routes";
import { Box } from "@mui/material";

export default function App() {
  const { pathname } = useLocation();
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <Box position={"relative"}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          {getRoutes(routes)}
          <Route path="/home" element={<Presentation />} />
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
        <Box
          position={"fixed"}
          top={"50%"}
          left={0}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"start"}
          justifyContent={"start"}
          gap={"10px"}
        >
          <Box
            width={"50px"}
            height={"50px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            bgcolor={"#fff"}
            zIndex={1000}
            borderRadius={"50%"}
            onClick={() =>
              window.open(
                "https://wa.me/918739831460?text=Hi%20there!%20I%20would%20like%20to%20know%20more%20about%20your%20services.",
                "_blank"
              )
            }
          >
            <img style={{ width: "45px" }} src={"/img/whatsapp.png"} />
          </Box>
          <Box
            width={"50px"}
            height={"50px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            bgcolor={"#fff"}
            borderRadius={"50%"}
            onClick={() => (window.location.href = "tel:+918739831460")}
          >
            <img style={{ width: "40px" }} src={"/img/phone-call.png"} />
          </Box>
        </Box>
      </ThemeProvider>
    </Box>
  );
}
