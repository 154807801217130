import img1 from "../../../../assets/images/travel-72870_1280.jpg";
import img2 from "../../../../assets/images/driving-7351259_1280.jpg";
import img3 from "../../../../assets/images/car-934056_1280.jpg";



export default [
  {
    image: img1,
    name: "Fast & Easy Booking",
    route: "/about-us",
    description: "You can book a cab online in just 2 minutes. It's easy & simple.",
  },
  {
    image: img2,
    name: "Doorstep Pickup & Drop",
    route: "/contact-us",
    description: "We provide free pickup & drop services at your doorstep.",
  },
  {
    image: img3,
    name: "No Booking Charges",
    route: "/",
    description: "Just pay confirmation charges & book your ride with us.",
  },
];
